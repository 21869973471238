export const environment = {
    production: true,
    country: "uy",
    api_siemprepago: "https://api.siemprepago.com/v1/Scripts/PWCheckout.js?key=leSdXpgb-cBPr8kE832Me2_I56ONV7HQ",
    API_URL: "https://fl-gen-apimgmt-prd.azure-api.net/",
    startPageUrl: "http://ucm.com.uy/",
    ig_payment: "../assets/js/ig-payment.js",
    baseStyles: "../assets/css/styles-uy.css",
    suscriptionKey: "ca117dedeed244018625e51fc6be426d",
    ambiente: "UyVal",
    RecaptKey: "6LdfRmkUAAAAAPwpxlI4t62x5eBCCi46RK7_4dZ6",
    recaptchaDisable: false,
    imagesRoute: "../../../assets/uy/images/",
    alertImage: "../../../assets/images-uy/Alert.png",
    checkImage: "../../../assets/images-uy/check.png",
    warningImage: "../../assets/images/warning.png",
    nombre: "ucm",
    codePassport: "2",
    lengthCell: "9",
    regexStartCellPhoneNumber: "^09",
    startNumberCell: "09",
    regularExpressionDocument: "^[0-9][0-9]{4,15}$",
    lengthPhone: "8",
    regexStartPhoneNumber: "^[24]",
    startNumberPhone: "2",
    countryName: "Uruguay",
    lengthPassword: 8,
    adultAge: 18,
    paymentMethodDebitInvoiceCode: "OF03",
    cityName: "localidad",
    mapCode: "UCM",
    softwareVersionCode: "Version Web",
    version: '8.5',
    ambient: '',
    apiKeyMaps: "AIzaSyCjLDebVboXGKpEa4C9E3i00yCgZo5yv9g",
    title: "Portal Clientes ucm",
    gtmURL: "https://www.googletagmanager.com/gtag/js?id=GTM-WD6TVRN",
    gtmConfig: "GTM-WD6TVRN",
    shopOnline: "https://shop.ucm.com.uy/",
    videoCallRecallTime: 30,
    browserVersionAvailable: {
      chrome: ">63",
      edge: ">13",
      firefox: ">57",
      safari: ">11",
      opera: ">=22",
      mobile: {
        androidBrowser: ">3.10"
      }
    },
    inactivityTime: 15,
    videoCallChangeStatusAvailable: true,
    useSentry: true
}